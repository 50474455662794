var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dayGift"},[_c('van-popup',{staticClass:"dayGift-pop",model:{value:(_vm.curShow),callback:function ($$v) {_vm.curShow=$$v},expression:"curShow"}},[_c('div',{staticClass:"card-content"},[_c('img',{staticClass:"close-btn",attrs:{"src":require("_videoGame/assets/dayGift/icon_36_close@2x.png")},on:{"click":_vm.close}}),_c('div',{staticClass:"title"},[_vm._v("— "+_vm._s(_vm.$t('每日礼包'))+" —")]),_c('div',{staticClass:"gift-list-box"},[_c('div',{staticClass:"gift-list"},_vm._l((_vm.chargeRulesList),function(item){return _c('div',{key:item.id,staticClass:"gift-item",attrs:{"id":'giftItem_' + item.id}},[_c('div',{staticClass:"gift-header",class:{
                'gift-header-buyed': item.buyState
              }},[_c('div',{staticClass:"header-icon"},[(item.buyState && !item.coinIos)?_c('img',{attrs:{"src":require("_videoGame/assets/dayGift/nike_icon.png")}}):_vm._e(),(!item.buyState && !item.coinIos)?_c('div',{staticClass:"header-num"},[_vm._v(" "+_vm._s(item.nobleId)+" ")]):_vm._e(),(item.coinIos && item.buyState)?_c('img',{attrs:{"src":item.iconOpen}}):_vm._e(),(item.coinIos && !item.buyState)?_c('img',{attrs:{"src":item.iconClose}}):_vm._e()]),(item.nobleId == 1)?_c('div',{staticClass:"header-title"},[_vm._v(" "+_vm._s(_vm.language == 'zh_CN' ? ("第" + (item.nobleId) + "天") : ((item.nobleId) + " kun"))+" ")]):_c('div',{staticClass:"header-title"},[_vm._v(" "+_vm._s(_vm.language == 'zh_CN' ? ("连续" + (item.nobleId) + "天购买") : ((item.nobleId) + " kun"))+" ")])]),_c('div',{staticClass:"gift-content",class:{
                'gift-content2': item.todayBuyItem
              }},[_c('div',{staticClass:"gift-text"},[_vm._v(" "+_vm._s(_vm.unitFormat(item.coin, 100000))+_vm._s(_vm.$t('金币'))+" ")]),_c('img',{staticClass:"coin-icon",attrs:{"src":item.coinImageUrl}}),(item.buyState)?_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t('已购买')))]):_vm._e(),(!item.buyState)?_c('div',{staticClass:"tips2"},[_vm._v(" "+_vm._s(_vm.$t('收益'))+_vm._s(item.name)+" % ")]):_vm._e()])])}),0)]),_c('div',{staticClass:"mid-tips"},[_vm._v(_vm._s(_vm.$t('连续购买礼包，解锁宝箱奖励')))]),_c('div',{staticClass:"box-block"},[_c('div',{staticClass:"day-list"},_vm._l((_vm.chargeRulesList),function(item){return _c('div',{key:item.id,staticClass:"day-default",attrs:{"id":'dayItem_' + item.id}},[(item.buyState)?_c('div',{staticClass:"day-buyed"}):_vm._e()])}),0),_c('div',{staticClass:"box-list"},_vm._l((_vm.chargeRulesList),function(item,index){return _c('div',{key:item.id,staticClass:"box-item",style:({
              left: ((index * 25) + "px")
            })},[(item.coinIos)?_c('div',{staticClass:"box-content"},[_c('div',{staticClass:"box-icon"},[(!item.buyState)?_c('img',{staticClass:"icon",attrs:{"src":item.iconClose}}):_c('img',{staticClass:"icon",attrs:{"src":item.iconOpen}}),_c('div',{staticClass:"box-num"},[_c('img',{staticClass:"coin",attrs:{"src":require("_videoGame/assets/invite_coin_icon.png")}}),_c('div',{staticClass:"num"},[_vm._v(" "+_vm._s(_vm.unitFormat(item.coinIos, 100000))+" ")])])]),_c('div',{staticClass:"day"},[_vm._v(_vm._s(item.nobleId)+_vm._s(_vm.$t('天')))])]):_vm._e()])}),0)]),_c('div',{staticClass:"footer-btn",class:{
          'footer-btn2': _vm.todayBuy
        },on:{"click":_vm.handlBuy}},[(!_vm.todayBuy)?_c('div',[_vm._v(" ￥"+_vm._s(_vm.chargeRulesList[0].money)+" "+_vm._s(_vm.$t('立即购买'))+" ")]):_c('div',[_vm._v(_vm._s(_vm.$t('明日再来')))])])])]),_c('PayMethod',{attrs:{"show":_vm.showAction,"productType":3,"productItem":_vm.productItem,"pageType":_vm.pageType},on:{"submit":_vm.recharge,"close":_vm.closeAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }