





















import { Component, Vue } from 'vue-property-decorator'
import { WalletModule } from '@/store/modules/wallet'
import { getAppLanguage } from '@/common/utils'
@Component
export default class extends Vue {
  get limit() {
    return WalletModule.chargeLimit
  }
  language = getAppLanguage()
  async getConfig() {
    //
  }
  get shoPop() {
    return WalletModule.showLimitPop
  }
  close() {
    WalletModule.setShowLimitPop(false)
  }
  get tipsText() {
    if (this.language === 'zh_CN') {
      return `单个账号每日充值限额为${this.limit}元，请合理安排充值时间哦～`
    } else {
      return `har bir supa nomuri bir kunda pakat ${this.limit} yuan kaqiliyalaydu，kimmat kaqilax waktigizni  muwapik orunlaxturug~`
    }
  }
  mounted() {
    this.getConfig()
  }
}
