



































import { Component, Vue, Prop } from 'vue-property-decorator'
import { getBannerByType } from '@/pages/videoGame/api'
import CardBuy from './CardBuy.vue'
import { PaymentModule } from '@/pages/index/modules/payment'
import { Toast } from 'vant'
import { getQueryVariable } from '@/common/utils'
import DayGift from './DayGift.vue'
import CompensatCard from './CompensatCard.vue'
@Component({
  components: {
    CardBuy,
    DayGift,
    CompensatCard
  }
})
export default class extends Vue {
  @Prop({ default: 'wallet' })
  page!: string
  list: any = []

  showCard = false
  showDayGift = false
  showCompensate = false
  async getBanner() {
    const { data } = await getBannerByType({ bannerType: 5 })
    this.list = data.list
  }
  handleClick(item: any) {
    //this.showDayGift = true
    if (item.jumpType == 2) {
      window.location.href = item.url
    } else if (item.jumpType == 3) {
      // if (this.page == 'wallet') {
      //   this.showCard = true
      // } else {
      //   const uid =
      //     PaymentModule.itunesInfo.id || PaymentModule.itunesInfo.user_id
      //   if (!uid) {
      //     Toast({
      //       message: '账号不能为空,请重新输入'
      //     })
      //   } else {
      //     this.showCard = true
      //   }
      // }
    } else if (item.jumpType == 4) {
      // if (this.page == 'wallet') {
      //   this.showDayGift = true
      // } else {
      //   const uid =
      //     PaymentModule.itunesInfo.id || PaymentModule.itunesInfo.user_id
      //   if (!uid) {
      //     Toast({
      //       message: '账号不能为空,请重新输入'
      //     })
      //   } else {
      //     this.showDayGift = true
      //   }
      // }
    } else if (item.jumpType == 5) {
      if (this.page == 'wallet') {
        this.showCompensate = true
      } else {
        const uid =
          PaymentModule.itunesInfo.id || PaymentModule.itunesInfo.user_id
        if (!uid) {
          Toast({
            message: '账号不能为空,请重新输入'
          })
        } else {
          this.showCompensate = true
        }
      }
    }
  }

  mounted() {
    if (getQueryVariable('showCardPop')) {
      this.showCard = true
    } else {
      //this.showDayGift = true
      //this.showCompensate = true
    }
    //this.showCard = true

    this.getBanner()
  }
}
