import { render, staticRenderFns } from "./CardBuyNew.vue?vue&type=template&id=ec346568&scoped=true&"
import script from "./CardBuyNew.vue?vue&type=script&lang=ts&"
export * from "./CardBuyNew.vue?vue&type=script&lang=ts&"
import style0 from "./CardBuyNew.vue?vue&type=style&index=0&id=ec346568&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec346568",
  null
  
)

export default component.exports