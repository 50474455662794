









































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getUserVipCardConfig, cardReceiveCoin } from '@/pages/videoGame/api'
import { getAppLanguage } from '@/common/utils'
import PayMethod from './PayMethod.vue'
import dayjs from 'dayjs'
import { isIOS, getQueryVariable } from '@/common/utils'
import { WalletModule } from '@/store/modules/wallet'
import { CommonModule } from '@/store/modules/common'
import {
  handlePayAliPay,
  handlePayWeChatH5,
  getOrderNew
} from '@/pages/index/api/payment'
import { PaymentModule } from '_index/modules/payment'
import { callAppFunc_onMonthCardWechatPay, callH5Func_nativePayResult } from '@/common/utils/bridge'
@Component({
  components: {
    PayMethod
  }
})
export default class extends Vue {
  @Prop({ default: 'wallet' })
  pageType!: string
  language = getAppLanguage()
  cardSend = getQueryVariable('game_give_amount') || ''
  get showAction() {
    return WalletModule.showCardMethod
  }
  get paymentHomeUid() {
    return PaymentModule.itunesInfo.user_id
  }
  get showRecivePop() {
    return WalletModule.cardRevicePop
  }
  get tipsText() {
    if (this.cardConfig.weekUserBuy && this.cardConfig.monthUserBuy) {
      return this.$t('周卡、月卡金币已于6点自动发放')
    } else if (this.cardConfig.weekUserBuy) {
      return this.$t('周卡金币已于6点自动发放')
    } else if (this.cardConfig.monthUserBuy) {
      return this.$t('月卡金币已于6点自动发放')
    }
    return ''
  }
  @Watch('showRecivePop')
  onShowRecivePopChange(val: boolean) {
    if (val && CommonModule.userId) {
      this.getConfig()
      CommonModule.getCoin()
    }
  }

  receiveCoin = 0
  cardConfig = {
    weekUserBuy: 0,
    weekReceive: 0,
    weekVipCardExpireTime: '',
    monthUserBuy: '0',
    monthReceive: 0,
    monthVipCardExpireTime: '',
    nowTime: '',
    nowTimestamp: 0,
    monthAppleId: '',
    monthBuySend: '',
    monthCardImgCn: '',
    monthCardImgUn: '',
    monthDaySend: ' ',
    monthPrice: '',
    monthSortNo: 0,
    weekAppleId: '',
    weekBuySend: '',
    weekCardImgCn: '',
    weekCardImgUn: ' ',
    weekDaySend: ' ',
    weekPrice: ' ',
    weekSortNo: 1,
    monthVipCardExpireTimestamp: 0,
    weekVipCardExpireTimestamp: 0
  }
  productItem = {
    money: 0,
    type: 'week',
    expareTime: '',
    coin: 0
  }
  computedExpireTime(nowTimeStamp: number, expireStamp: number, type: string) {
    const difDay = Math.floor((expireStamp - nowTimeStamp) / 86400000)

    if (difDay > 3) {
      const year = dayjs(expireStamp).year()
      const month = dayjs(expireStamp).month() + 1
      const day = dayjs(expireStamp).date()

      if (this.language == 'zh_CN') {
        return (
          (type == 'week' ? '周卡于' : '月卡于') +
          `${year}年${month}月${day}日到期`
        )
      } else {
        return `wakti wakti ${year}-yili ${month}-aynig ${day}-kuni toxidu`
      }
    } else {
      if (difDay == 0) {
        if (this.language == 'zh_CN') {
          return (type == 'week' ? '周卡于' : '月卡于') + `今天过期`
        } else {
          if (type == 'week') {
            return `haptilik kartinig bugun wakti toxidu`
          } else {
            return `aylik kartinig bugun wakti toxidu`
          }
        }
      }
      if (this.language == 'zh_CN') {
        return (type == 'week' ? '周卡于' : '月卡于') + `${difDay}天后过期`
      } else {
        if (type == 'week') {
          return `haptilik kartinig ${difDay} kundin keyin wakti toxidu`
        } else {
          return `aylik kartinig ${difDay} kundin keyin wakti toxidu`
        }
      }
    }
  }
  get cardList() {
    const item = {
      type: 'week',
      img:
        this.language === 'zh_CN'
          ? this.cardConfig.weekCardImgCn
          : this.cardConfig.weekCardImgUn,
      price: this.cardConfig.weekPrice,
      sort: this.cardConfig.weekSortNo,
      daySend: this.cardConfig.weekDaySend,
      buySend: this.cardConfig.weekBuySend,
      appleId: this.cardConfig.weekAppleId,
      userBuy: this.cardConfig.weekUserBuy,
      receive: this.cardConfig.weekReceive,
      expireTimestamp: this.cardConfig.weekVipCardExpireTimestamp,
      expireTime: this.computedExpireTime(
        this.cardConfig.nowTimestamp,
        this.cardConfig.weekVipCardExpireTimestamp || 0,
        'week'
      )
    }
    const item2 = {
      type: 'month',
      img:
        this.language === 'zh_CN'
          ? this.cardConfig.monthCardImgCn
          : this.cardConfig.monthCardImgUn,
      price: this.cardConfig.monthPrice,
      sort: this.cardConfig.monthSortNo,
      daySend: this.cardConfig.monthDaySend,
      buySend: this.cardConfig.monthBuySend,
      appleId: this.cardConfig.monthAppleId,
      userBuy: this.cardConfig.monthUserBuy,
      receive: this.cardConfig.monthReceive,
      expireTimestamp: this.cardConfig.monthVipCardExpireTimestamp,
      expireTime: this.computedExpireTime(
        this.cardConfig.nowTimestamp,
        this.cardConfig.monthVipCardExpireTimestamp || 0,
        'month'
      )
    }
    return item.sort <= item2.sort ? [item, item2] : [item2, item]
  }
  async getConfig() {
    const { data } = await getUserVipCardConfig(this.paymentHomeUid)
    this.cardConfig = data
  }
  handlePay(item: any) {
    this.productItem.money = item.price
    this.productItem.type = item.type
    this.productItem.coin = item.buySend || 0 // 购买时立即赠送的金币数量
    const startTime =
      item.userBuy == 0
        ? new dayjs(this.cardConfig.nowTime)
        : new dayjs(item.expireTimestamp)
    let year, month, day
    if (item.type == 'week') {
      year = startTime.add(7, 'day').year()
      month = startTime.add(7, 'day').month() + 1
      day = startTime.add(7, 'day').date()
    } else {
      year = startTime.add(30, 'day').year()
      month = startTime.add(30, 'day').month() + 1
      day = startTime.add(30, 'day').date()
    }
    if (this.language == 'zh_CN') {
      this.productItem.expareTime =
        (item.type == 'week' ? '周卡于' : '月卡于') +
        `${year}年${month}月${day}日到期`
    } else {
      this.productItem.expareTime = `wakti ${year}-yili ${month}-aynig ${day}-kuni toxidu`
    }
    WalletModule.setShowCardMethod(true)
  }
  get payType() {
    return WalletModule.payType
  }
  rechargLoading = false
  async recharge() {
    if (this.rechargLoading) return
    this.rechargLoading = true

    const obj = {
      uid:
        CommonModule.userId ||
        PaymentModule.itunesInfo.id ||
        PaymentModule.itunesInfo.user_id,
      charge_type: this.productItem.type == 'week' ? 4 : 5
    }
    try {
      if (this.payType === 1) {
        // await this.payWeChatH5(obj)
        // app呼起微信支付参数不一致
        const nativeObj = {
          cardType: this.productItem.type == 'week' ? 1 : 2, // 商品类型; 1周卡  2月卡
          price: this.productItem.money, // 商品价格
          coins: this.productItem.coin || 0  // 购买时可获得的金币数
        }
        await this.payWeChatH5Native(nativeObj)
      } else if (this.payType === 2) {
        await this.payAliPay(obj)
      }
    } finally {
      this.rechargLoading = false
      //Toast.clear()
    }
  }

  async payWeChatH5(obj: any) {
    //微信
    const front = this.pageType == 'payment' ? 1 : isIOS ? 2 : 3
    try {
      const weiInfo = await handlePayWeChatH5(obj, front)
      const {
        data: { mweb_url = '' }
      } = weiInfo
      if (weiInfo.data.code == -1) {
        WalletModule.setShowCardMethod(false)
        WalletModule.setChargeLimit(weiInfo.data.msg)
        WalletModule.setShowLimitPop(true)

        return
      }
      sessionStorage.setItem('onec', '1')
      setTimeout(() => {
        window.location.href = mweb_url
      }, 500)
    } catch (error) {
      console.error(error)
    }
  }
  // 呼起客户端进行微信支付
  async payWeChatH5Native(obj: any) {
    try {
      callAppFunc_onMonthCardWechatPay(obj)
    } catch (error) {
      console.error(error)
    }
  }
  async payAliPay(obj: any) {
    //支付宝
    const front = this.pageType == 'payment' ? 1 : isIOS ? 2 : 3
    try {
      const aliPay = await handlePayAliPay(obj, front)
      const {
        data: { html = '' }
      } = aliPay
      if (aliPay.data.code == -1) {
        WalletModule.setShowCardMethod(false)
        WalletModule.setChargeLimit(aliPay.data.msg)
        WalletModule.setShowLimitPop(true)
        return
      }
      sessionStorage.setItem('onec', '1')
      const _a = document.createElement('div')
      _a.innerHTML = html
      document.body.append(_a)
      setTimeout(() => {
        document.forms[0].submit()
      }, 500)
    } catch (error) {
      console.error(error)
    }
  }

  reviceLoading = false
  async submitRevice(item: any) {
    if (this.reviceLoading) return
    this.reviceLoading = true
    const params = {
      type: item.type == 'week' ? 1 : 2,
      uid: CommonModule.userId || this.paymentHomeUid
    }
    try {
      await cardReceiveCoin(params)
      this.cardSend = item.daySend
      WalletModule.setCardRevicePop(true)
    } finally {
      this.reviceLoading = false
    }
  }
  closeRecivePop() {
    WalletModule.setCardRevicePop(false)
  }
  close() {
    this.$emit('close')
  }
  closeAction() {
    WalletModule.setShowCardMethod(false)
  }
  mounted() {
    this.getConfig()
    // 注册支付成功后的回调
    callH5Func_nativePayResult((resp) => {
      console.log('callH5Func_nativePayResult', resp);
      this.getConfig()
    })
  }
}
