





















































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getAppLanguage } from '@/common/utils'
import PayMethod from './PayMethod.vue'
import { isIOS, getQueryVariable, unitFormat } from '@/common/utils'
import { WalletModule } from '@/store/modules/wallet'
import { CommonModule } from '@/store/modules/common'
import {
  handlePayAliPay,
  handlePayWeChatH5,
  getOrderNew
} from '@/pages/index/api/payment'
import { PaymentModule } from '_index/modules/payment'
import { getDayGiftRechargeList } from '@/pages/videoGame/api'
@Component({
  components: {
    PayMethod
  }
})
export default class extends Vue {
  @Prop({ default: false })
  show!: boolean
  @Watch('show')
  async onShowChange(val: boolean) {
    if (val == true) {
      await this.getConfig()
    }
    this.curShow = val
    await this.$nextTick()
    this.autoScrool()
  }
  curShow = false
  language = getAppLanguage()
  chargeRulesList: any = []
  todayBuy = false
  get showAction() {
    return WalletModule.showDayGiftMethod
  }
  get paymentHomeUid() {
    return PaymentModule.itunesInfo.user_id
  }
  productItem = {
    money: this.chargeRulesList.length ? this.chargeRulesList[0].money : 0,
    type: 7,
    expareTime: '',
    buyDesc: '',
    changeid: ''
  }
  @Prop({ default: 'wallet' })
  pageType!: string
  curGiftInfo: any = {
    money: 0,
    coin: 0,
    changeid: '',
    coinIos: 0
  }
  unitFormat = unitFormat
  get payType() {
    return WalletModule.payType
  }
  giftNode: any = {}
  dayNode: any = {}
  async getConfig() {
    const { data } = await getDayGiftRechargeList({ uid: this.paymentHomeUid })
    this.chargeRulesList = data.chargeRulesList
    this.todayBuy = data.todayBuy
    data.chargeRulesList.forEach((item: any) => {
      if (item.todayBuyItem) {
        this.curGiftInfo.money = item.money
        this.curGiftInfo.coin = item.coin
        this.curGiftInfo.changeid = item.id
        this.curGiftInfo.coinIos = item.coinIos
      }
    })
  }
  autoScrool() {
    this.giftNode = document.getElementById(
      `giftItem_${this.curGiftInfo.changeid}`
    )

    this.dayNode = document.getElementById(
      `dayItem_${this.curGiftInfo.changeid}`
    )
    console.log('giftNode', this.giftNode)
    console.log('dayNode', this.dayNode)
    this.giftNode.scrollIntoView()
    this.dayNode.scrollIntoView()
  }
  handlBuy() {
    if (this.todayBuy) {
      return
    }
    this.productItem.money = this.curGiftInfo.money
    const coin = this.curGiftInfo.coin + this.curGiftInfo.coinIos

    if (this.language == 'zh_CN') {
      this.productItem.buyDesc = `充值${this.curGiftInfo.money}元获得${coin}金币`
    } else {
      this.productItem.buyDesc = `${this.curGiftInfo.money} yuan kaqilisigiz ${coin} taggiga erixisiz`
    }
    WalletModule.setShowDayGiftMethod(true)
  }
  close() {
    this.$emit('close')
  }

  rechargLoading = false
  async recharge() {
    if (this.rechargLoading) return
    this.rechargLoading = true

    const obj = {
      uid:
        CommonModule.userId ||
        PaymentModule.itunesInfo.id ||
        PaymentModule.itunesInfo.user_id,
      charge_type: this.productItem.type,
      changeid: this.curGiftInfo.changeid
    }
    try {
      if (this.payType === 1) {
        await this.payWeChatH5(obj)
      } else if (this.payType === 2) {
        await this.payAliPay(obj)
      }
    } finally {
      this.rechargLoading = false
      //Toast.clear()
    }
  }
  async payWeChatH5(obj: any) {
    //微信
    const front = this.pageType == 'payment' ? 1 : isIOS ? 2 : 3
    try {
      const weiInfo = await handlePayWeChatH5(obj, front)
      const {
        data: { mweb_url = '' }
      } = weiInfo
      if (weiInfo.data.code == -1) {
        WalletModule.setShowDayGiftMethod(false)
        WalletModule.setChargeLimit(weiInfo.data.msg)
        WalletModule.setShowLimitPop(true)

        return
      }
      sessionStorage.setItem('onec', '1')
      setTimeout(() => {
        window.location.href = mweb_url
      }, 500)
    } catch (error) {
      console.error(error)
    }
  }
  async payAliPay(obj: any) {
    //支付宝
    const front = this.pageType == 'payment' ? 1 : isIOS ? 2 : 3
    try {
      const aliPay = await handlePayAliPay(obj, front)
      const {
        data: { html = '' }
      } = aliPay
      if (aliPay.data.code == -1) {
        WalletModule.setShowDayGiftMethod(false)
        WalletModule.setChargeLimit(aliPay.data.msg)
        WalletModule.setShowLimitPop(true)
        return
      }
      sessionStorage.setItem('onec', '1')
      const _a = document.createElement('div')
      _a.innerHTML = html
      document.body.append(_a)
      setTimeout(() => {
        document.forms[0].submit()
      }, 500)
    } catch (error) {
      console.error(error)
    }
  }

  reviceLoading = false
  closeAction() {
    WalletModule.setShowDayGiftMethod(false)
  }
}
