
















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getAppLanguage, unitFormat } from '@/common/utils'
import PayMethod from './PayMethod.vue'
import { isIOS, getQueryVariable } from '@/common/utils'
import { WalletModule } from '@/store/modules/wallet'
import { CommonModule } from '@/store/modules/common'
import {
  handlePayAliPay,
  handlePayWeChatH5,
  getOrderNew
} from '@/pages/index/api/payment'
import { PaymentModule } from '_index/modules/payment'
import { getRichTextContent } from '_user/api'
import { getProtectBuyConfigs } from '@/pages/videoGame/api'
@Component({
  components: {
    PayMethod
  }
})
export default class extends Vue {
  @Prop({ default: false })
  show!: boolean
  @Watch('show')
  async onShowChange(val: boolean) {
    if (val == true) {
      await this.getConfig()
    }
    this.curShow = val
  }
  curShow = false
  language = getAppLanguage()
  chargeRulesList: any = []
  unitFormat = unitFormat
  get showAction() {
    return WalletModule.showCompensatMethod
  }
  get paymentHomeUid() {
    return PaymentModule.itunesInfo.user_id
  }
  productItem = {
    money: 0,
    type: 7,
    expareTime: '',
    buyDesc: '',
    changeid: ''
  }
  @Prop({ default: 'wallet' })
  pageType!: string
  curGiftInfo: any = {
    money: 0,
    coin: 0,
    changeid: ''
  }
  get payType() {
    return WalletModule.payType
  }
  close() {
    this.$emit('close')
  }
  showRule = false
  closeRule() {
    this.showRule = false
  }
  ruleContent = ''
  async init() {
    const params = { id: '60' }
    const { data } = await getRichTextContent(params)
    this.ruleContent = data.postContent
  }
  async getConfig() {
    const { data } = await getProtectBuyConfigs({ uid: this.paymentHomeUid })
    this.chargeRulesList = data.list
  }
  handlBuy(item: any) {
    this.curGiftInfo.money = item.money
    this.curGiftInfo.coin = item.coin
    this.curGiftInfo.changeid = item.id
    this.productItem.money = this.curGiftInfo.money

    if (this.language == 'zh_CN') {
      this.productItem.buyDesc = `充值${this.curGiftInfo.money}元获得${this.curGiftInfo.coin}金币`
    } else {
      this.productItem.buyDesc = `${this.curGiftInfo.money} yuan kaqilisigiz ${this.curGiftInfo.coin} taggiga erixisiz`
    }
    WalletModule.setShowCompensatMethod(true)
  }
  goHome() {
    this.$toast(this.$t('请前往房间使用'))
  }
  rechargLoading = false
  async recharge() {
    if (this.rechargLoading) return
    this.rechargLoading = true

    const obj = {
      uid:
        CommonModule.userId ||
        PaymentModule.itunesInfo.id ||
        PaymentModule.itunesInfo.user_id,
      charge_type: this.productItem.type,
      changeid: this.curGiftInfo.changeid
    }
    try {
      if (this.payType === 1) {
        await this.payWeChatH5(obj)
      } else if (this.payType === 2) {
        await this.payAliPay(obj)
      }
    } finally {
      this.rechargLoading = false
      //Toast.clear()
    }
  }
  async payWeChatH5(obj: any) {
    //微信
    const front = this.pageType == 'payment' ? 1 : isIOS ? 2 : 3
    try {
      const weiInfo = await handlePayWeChatH5(obj, front)
      const {
        data: { mweb_url = '' }
      } = weiInfo
      if (weiInfo.data.code == -1) {
        WalletModule.setShowCompensatMethod(false)
        WalletModule.setChargeLimit(weiInfo.data.msg)
        WalletModule.setShowLimitPop(true)

        return
      }
      sessionStorage.setItem('onec', '1')
      setTimeout(() => {
        window.location.href = mweb_url
      }, 500)
    } catch (error) {
      console.error(error)
    }
  }
  async payAliPay(obj: any) {
    //支付宝
    const front = this.pageType == 'payment' ? 1 : isIOS ? 2 : 3
    try {
      const aliPay = await handlePayAliPay(obj, front)
      const {
        data: { html = '' }
      } = aliPay
      if (aliPay.data.code == -1) {
        WalletModule.setShowCompensatMethod(false)
        WalletModule.setChargeLimit(aliPay.data.msg)
        WalletModule.setShowLimitPop(true)
        return
      }
      sessionStorage.setItem('onec', '1')
      const _a = document.createElement('div')
      _a.innerHTML = html
      document.body.append(_a)
      setTimeout(() => {
        document.forms[0].submit()
      }, 500)
    } catch (error) {
      console.error(error)
    }
  }

  reviceLoading = false
  closeAction() {
    WalletModule.setShowCompensatMethod(false)
  }
  mounted() {
    this.init()
  }
}
